<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7" justify="space-between">
      <v-col cols="12" sm="6" md="8">
        <div>
          <h1>REVIEW BID WARRANTIES</h1>
        </div>
      </v-col>
      <v-col cols="12" md="2" sm="3">
        <div class="text-right">
          <v-btn
            color="primary"
            @click="
              $router.push({
                name: 'EnterBid',
                params: { id: $route.params.id },
              })
            "
            >Back
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <customer-info class="mb-8" />

    <div class="d-flex">
      <div class="d-flex mr-8">
        <h3 class="mr-8">Customer</h3>
        Nicole
      </div>
      <div class="d-flex mr-8">
        <h3 class="mr-8">Bid Number</h3>
        10465
      </div>
    </div>

    <v-row>
      <v-col cols="12" class="customer-search-table">
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1 cursor-pointer"
        >
          <template v-slot:item.includeStd="{ item }">
            <v-checkbox v-model="item.includeStd"></v-checkbox>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-textarea
          label="Comments:"
          auto-grow
          outlined
          rows="3"
          row-height="40"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";

export default {
  components: { CustomerInfo },
  name: "ReviewWarranty.vue",
  data() {
    return {
      componentURL: "",
      evaluation_id: this.$route.params.id,
      headers: [
        { text: "Bid Item", value: "bidItem" },
        { text: "Std Warranty", value: "stdWarranty" },
        { text: "Std Warranty language", value: "stdWarrantyLanguage" },
        { text: "Include Std", value: "includeStd" },
        { text: "Text Add", value: "textAdd" },
      ],
      items: [
        {
          bidItem: '6" Discharge Basin w/Grate',
          stdWarranty: "0",
          stdWarrantyLanguage: "",
          includeStd: true,
          textAdd: "",
        },
        {
          bidItem: "PS-C33 Sump Pump 1/3 hp Primary w/Backup - Best",
          stdWarranty: "Manufacturer",
          stdWarrantyLanguage: "Include mfg warranty.",
          includeStd: false,
          textAdd: "",
        },
        {
          bidItem: "Bell Basin",
          stdWarranty: "0",
          stdWarrantyLanguage: "",
          includeStd: true,
          textAdd: "",
        },
        {
          bidItem: "Epoxy Injection",
          stdWarranty: "1/2 yr",
          stdWarrantyLanguage:
            "Include 1 year Fl warranty - 2years if part of Fl waterproofing system",
          includeStd: true,
          textAdd: "",
        },
        {
          bidItem: "Mortar",
          stdWarranty: "0",
          stdWarrantyLanguage: "",
          includeStd: true,
          textAdd: "",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
